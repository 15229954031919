import { EnergyType } from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { MandateDataType } from '@app/modules/customer-zone/consumption/models/mandates.interface';

export enum AccessRight {
  createAccount = 'createAccount',
  seeGenericMessage = 'seeGenericMessage',
  viewBalance = 'viewBalance',
  payOnline = 'payOnline',
  viewInvoices = 'viewInvoices',
  viewPaymentPlan = 'viewPaymentPlan',
  switchClientRef = 'switchClientRef',
  bindClientRef = 'bindClientRef',
  accessInvoices = 'accessInvoices',
  accessAdvance = 'accessAdvance',
  modifyAdvance = 'modifyAdvance',
  accessPaymentMethod = 'accessPaymentMethod',
  accessProductSwap = 'accessProductSwap',
  activateDom = 'activateDom',
  activateDomv = 'activateDomv',
  activateBankTransfer = 'activateBankTransfer',
  modifyRefundAccount = 'modifyRefundAccount',
  modifyInvoicingMethod = 'modifyInvoicingMethod',
  modifyContactDetails = 'modifyContactDetails',
  accessMeterReading = 'accessMeterReading',
  accessMyConsumption = 'accessMyConsumption',
  accessContracts = 'accessContracts',
  accessMyOtherProducts = 'accessMyOtherProducts',
  accessSponsorship = 'accessSponsorship',
  accessTevc = 'accessTevc',
  accessInjection = 'accessInjection',
  excludeVAT = 'excludeVAT',
  showZoomit = 'showZoomit',
  accessBuildingInfo = 'accessBuildingInfo',
  accessMove = 'accessMove',
  viewTariffCard = 'viewTariffCard',
  accessIndex56 = 'accessIndex56',
  privateChat = 'privateChat',
  businessChat = 'businessChat',
  viewUpsell = 'viewUpsell',
  viewRegulDetails = 'viewRegulDetails',
  personalContactDetails = 'personalContactDetails',
  businessContactDetails = 'businessContactDetails',
  personalConsents = 'personalConsents',
  businessConsents = 'businessConsents',
  accessEnergyInsights = 'accessEnergyInsights',
  sites = 'sites',
}

export enum EliqAccessRight {
  seeMandatePage = 'seeMandatePage',
  seeHomeProfileTabAndMyHome = 'seeHomeProfileTabAndMyHome',
  seeAdvicePage = 'seeAdvicePage',
}

export enum AccessRightOperator {
  negation = '!',
  conjunction = 'AND',
  disjunction = 'OR',
}

export type AccessRights = {
  [key in AccessRight]: boolean;
};

export interface UserType {
  isSocial: boolean;
  isSolar: boolean;
  hasSolarPanel: boolean;
  hasContracts: boolean;
  hasChargingStations: boolean;
  myEnergy?: boolean | MyEnergyActionsPerEAN;
  deliveryPoints: DeliveryPoint[];
  accessRights: AccessRights;
  activeReference: string;
  activeSiteId: string;
  myEnergyFlow: MyEnergyActionsPerEAN;
}

export interface EliqAccessRights {
  accessPerSiteId?: {
    isEnergyInsightFeatureEligible?: boolean;
    eliqEligible?: boolean;
    seeHomeProfileTabAndMyHome?: boolean;
    seeHomeProfileProgressBar?: boolean;
    seeBannerIfBattery?: boolean;
    seeDisclaimerEstimation?: boolean;
    seeSmartMeterInstallation?: boolean;
    seeMandatePage?: boolean;
    crossSell?: boolean;
    seeAdvicePage?: boolean;
    hasRequiredMandate?: boolean;
  };
  profileByDeliveryPointReference?: ProfilePerEAN;
  widgetAccessPerDeliveryPointReference?: {
    deliveryPointReference: string;
    access: {
      seeWidgetElectricityConsumptionCurrentMonthVersusEstimation?: boolean;
      seeWidgetGasPeriodPerConsumption?: boolean;
      seeWidgetPreviousConsumptionMeasurement?: boolean;
      seeWidgetLastIndexOverviewAndCtaIndexTransmission?: boolean;
      seeWidgetAutoConsumptionChart?: boolean;
      seeWidgetPeakPower?: boolean;
      seeWidgetConsumptionIntermediateIndexes?: boolean;
      seeWidgetGasCurrentMonthVersusEstimation?: boolean;
      seeWidgetConsumptionOrInjectionPreviousPreviousAndFurtherConsumption?: boolean;
      seeWidgetDisagreement?: boolean;
      seeSmartMeterInstallation?: boolean;
      seeWidgetElectricityPeriodPerConsumption?: boolean;
      seeWidgetConsumptionOrInjectionChartMeasurementAndEstimateFuture?: boolean;
    };
  }[];
}

export interface Profile {
  energyType: EnergyType;
  injection: number;
  smart: boolean;
  fluvius: boolean;
  meterReadingFrequency: string;
  budgetMeter: boolean;
  billingFrequency: string;
  activeFluviusMandate: boolean;
  granularity: MandateDataType;
}

export interface ProfilePerEAN {
  [ean: string]: Profile;
}

export interface MyEnergyActionsPerEAN {
  [ean: string]: MyEnergyActions;
}

export interface MyEnergyActions {
  accessToMyEnergyRecommendation: boolean;
}

export interface DeliveryPoint {
  energy: EnergyType;
  reference: string;
  meterNumber?: string;
  smartMeter?: boolean;
  injection?: boolean;
}

export enum CustomerType {
  b2c = 'B2C',
  b2b = 'B2B',
}

export enum AdvancesChangeOrigin {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  BASED_ON_INDEX = 'BASED_ON_INDEX', // My energy flow - call from page fr/invoices/advance-payment
  // eslint-disable-next-line @typescript-eslint/naming-convention
  USER_REQUEST = 'USER_REQUEST', // Normal flow - call from page /fr/particuliers/consumptions
}
